.home {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: 100vw;
  border-left: 20px solid #d1ff3b;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  color: #ffffff;
  margin: -10px;
  padding: -10px;
}

.image-logo {
  height: 80px;
  width: 80px;
}
