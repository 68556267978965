$f-size-small: 0.75rem;
// COLOURS
$yellow: #d1ff3b;
$red: #f50b00;
$grey: #888888;
$f-family: 'Roboto', sans-serif;

$border-radius: 2px;

$transition-duration: 300ms;

// SPECIFIC CLIENT VARIABLES
$toyota: #eb0a1e;
$vw: #0057a5;
$lexus: #000;

// THESE FOUR VARIABLES NEED TO BE
// ADDED INTO THE CLIENT SETUP PROCESS
$primary: $yellow;
$bgd-img: url('../../assets/imgs/bgd-lexus.png');
$bgd-overlay: rgba(0, 0, 0, 0.5);
$bgd-position: right;

// ICOMOON VARIABLES
$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../../assets/fonts/icomoon' !default;

$icon-email: '\e900';
$icon-phone: '\e942';

// BODY LAYOUT
html,
body,
#root,
.app-wrapper {
  height: 100%;
}

.pg-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  margin: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  &.bgd {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.branding-myadbox {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 3;
  .logo {
    height: 62px;
  }
  @media screen and (min-width: 992px) {
    top: 2rem;
    left: 2rem;
    position: fixed;
  }
}

// .cube-grid {
//   display: none;
//   @media screen and (min-width: 992px) {
//     z-index: -1;
//     display: block;
//     position: fixed;
//     right: 0;
//     bottom: -112px;
//     right: -170px;
//     width: 720px;
//     height: 513px;
//   }
//   @media screen and (min-width: 1400px) {
//     z-index: 2;
//   }
// }

.app-wrapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  z-index: 2;
  //background-color: rgba(0, 0, 0, 0.6);
}

.pg-wrap {
  box-sizing: border-box;
  min-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 7rem 0 2rem;
  @media screen and (min-width: 992px) {
    min-width: 500px;
    margin: 0 auto;
    border-left: 10px solid $yellow;
    border-bottom: 10px solid #fff;
    border-right: 10px solid #fff;
    padding: 0;
  }
}

.header {
  margin-bottom: 2.5rem;
  width: 100%;
}

.content {
  border-radius: $border-radius;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  color: #fff;
  min-width: 320px;
  box-sizing: border-box;
  position: relative;
  @media screen and (min-width: 992px) {
    min-width: 400px;
    max-width: 600px;
    padding: 3rem;
  }
}

.section {
  margin-bottom: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 1.5rem;
  //margin-bottom: 1.5rem;
  h3 {
    margin: 0 0 1rem;
    color: #fff;
  }
  .btn {
    width: 100%;
  }
  &.client-login {
    padding-top: 0;
    > p {
      color: #fff;
      font-style: italic;
      font-size: $f-size-small;
      text-align: center;
    }
  }
}

.support-method {
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  > a {
    color: #fff;
    transition: color $transition-duration ease;
    text-decoration: none;
    line-height: 1;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
      i {
        text-decoration: none;
      }
    }
  }
  i {
    transition: color $transition-duration ease;
    display: inline-block;
    padding: 0 0.25rem 0.125rem 0;
    text-decoration: none;
  }
  span {
    padding: 0 0 0.125rem;
    border-bottom: 1px solid transparent;
    transition: border 300ms ease;
    font-size: $f-size-small;
  }
}

.helper-wrapper {
  font-size: $f-size-small;
  align-items: center;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding-top: 0.5rem;
}

.new-password-criteria {
  margin-bottom: 2rem;
  font-size: 0.85rem;
}

.helper-links {
  text-align: right;
  width: 100%;
  margin-bottom: 1.5rem;
  > div {
    display: block;
    margin-bottom: 0.5rem;
  }
  a {
    display: inline-block;
    font-size: $f-size-small;
  }
}

// FORM STYLES
.form-field {
  margin-bottom: 3rem;
  position: relative;
  width: 100%;
  font-size: 1rem;
  display: block;
  .custom-branding & {
    margin-bottom: 2.25rem;
  }
  > label {
    color: #fff;
    margin-bottom: 0.25rem;
    display: block;
    font-size: $f-size-small;
    cursor: text;
    transition: all 250ms ease;
  }
  input:-internal-autofill-selected {
    background: transparent;
  }
  > input {
    box-sizing: border-box;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    width: 100%;
    transition: border 250ms ease;
    padding: 0.5rem 0;
    display: block;
    color: #fff;
    margin-bottom: 0.125rem;
    font-size: 1rem;
    &:invalid {
      outline: none;
      box-shadow: none;
    }
    &:focus {
      outline: none;
      color: #fff;
    }
  }
}

::-webkit-input-placeholder,
:-ms-input-placeholder,
::placeholder {
  color: $grey;
  // font-style: italic;
}

.error-msg {
  position: absolute;
  left: 0;
  padding-top: 0.25rem;
}

.error-msg {
  font-size: $f-size-small;
  color: $red;
}

.back-login {
  &.reset-password {
    text-align: right;
    float: right;
    font-size: $f-size-small;
  }
}

.ui.btn {
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: $border-radius;
  font-size: 1rem;
  font-family: $f-family;
  font-weight: 700;
  border: 0;
  line-height: 20px;
  transition: all 250ms ease;
}

.ui.btn-primary {
  color: #fff;
  transition: filter $transition-duration ease;
  .content & {
    width: 100%;
  }
  &:hover,
  &:focus {
    color: #fff;
    filter: brightness(80%);
  }
}

.btn-link {
  background: transparent;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid transparent;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  transition: border 300ms ease;
  font-size: $f-size-small;
  cursor: pointer;
  .reset-password-form &,
  .set-new-password-form &,
  .send-verify-form & {
    float: right;
  }
  .reset-password-form &,
  .set-new-password-form & {
    padding-top: 0.5rem;
  }
  .change-password-form & {
    float: right;
    padding-top: 1.5rem;
  }
  &.reset-password {
    float: right;
  }
  &:hover {
    color: #fff;
  }
}

.reset-password-header {
  font-size: 1.2rem;
  font-weight: 200;
  text-align: left;
}

.btn-wrapper {
  > .btn,
  .ui.btn {
    margin-top: 1rem;
  }
}

.tagline {
  display: block;
  text-align: center;
  margin-top: 2.5rem;
  font-size: 0.85rem;
  color: $grey;
  text-transform: uppercase;
}

// .form-description {

//   margin-block-start: 1em;
//   margin-block-end: 1em;
//   margin-inline-start: 0px;
//   margin-inline-end: 0px;

//   font-size: 14px;
//   margin-top: 2.5rem !important;
// }

// .reset-password-form,
// .change-password-form,
// .set-new-password-form {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;

//   .ui.error.message {
//     max-width: 500px;
//   }
// }

.error-msg {
  color: $grey;
  text-align: left;
  font-style: italic;
  &::before {
    content: '* ';
    color: $red;
  }
  .helper-wrapper & {
    padding-top: 0;
  }
}
.submitted-error-msg {
  color: red;
  text-align: left;
  margin-bottom: 1.5rem;
  //text-transform: capitalize;
  font-size: $f-size-small;
  // .helper-wrapper & {
  //   float: left;
  //   padding-bottom: 1px;
  //   margin-bottom: 0;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  // }

  .change-password-form & {
    margin-bottom: 0;
  }
  .form-field-password-confirmation & {
    max-width: 50%;
    text-align: right;
  }
  .login-pwd-field & {
    max-width: 70%;
    text-align: right;
  }
}

// WEBKIT OVERWRITES

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  border-bottom: 1px solid #fff;
  -webkit-text-fill-color: $grey;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 1rem;
}

.header {
  margin-bottom: 2.5rem;
  width: 100%;
  color: #fff;
  text-align: center;
  > h2 {
    margin: 0;
  }
}

// RESET PASSWORD PAGE
.reset-password-form,
.send-verify-form {
  .back-login {
    margin-top: 2rem;
  }
}

// ICOMOON SHIT - SHOULD GET MOVED TO A SEPARATE SCSS FILE.
@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?59l7yp')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?59l7yp')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?59l7yp##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email {
  &:before {
    content: $icon-email;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.section {
  margin-bottom: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  h3 {
    margin: 0 0 0.75rem;
    color: #fff;
  }
}

// Language picker component
.lang-picker {
  display: flex;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 3;
  > span {
    margin: 0;
    font-size: $f-size-small;
    &::before {
      content: '|';
      padding: 0 0.25rem;
    }
    &:first-child::before {
      content: '';
      padding-left: 0;
    }
  }
  button {
    padding: 0;
    &:hover {
    }
  }
  // ~ .sign-in-form {
  //   margin-top: 1rem;
  // }
}

.link-register {
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 2.5rem;
  font-size: 0.85rem;
  color: $grey;
  text-transform: uppercase;
  transition: color $transition-duration ease;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

// HEADER FOR CLIENT
.login-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  // background-color: $grey;
  padding: 0.5rem 0;
}

.client-app-name {
  // background-image: url('../../assets/imgs/logo-adbuilder-vw.png');
  // background-repeat: no-repeat;
  // display: block;
  // width: 115px;
  // height: 19px;
  margin-right: 2.5rem;
  > img {
    display: block;
    height: 16px;
    @media only screen and (min-width: 992px) {
      height: 18px;
      max-width: 228px;
    }
  }
}

.client-branding {
  // background-image: url('../../assets/imgs/logo-vw.png');
  // background-repeat: no-repeat;
  // display: block;
  // width: 60px;
  // height: 60px;
  //margin-right: 2rem;
  //display: flex;
  > img {
    height: 40px;
    float: right;
    @media only screen and (min-width: 992px) {
      height: 50px;
    }
  }
}

.auth-loader {
  .ui.prompt-message {
    display: block;
    width: 100% !important;
  }
}

.ui.loading.loading.loading.loading.loading.loading.button {
  position: relative;
  cursor: default;
  text-shadow: none !important;
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}
.ui.loading.button {
  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.5rem 0em 0em -0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 500rem;
  }
}
.ui.loading.button:before {
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}

.ui.loading.button:after {
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-color: #ffffff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0px 0px 0px 1px transparent;
  box-shadow: 0px 0px 0px 1px transparent;
}

.ui.labeled.icon.loading.button .icon {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ui.basic.loading.button:not(.inverted):before {
  border-color: rgba(0, 0, 0, 0.1);
}
.ui.basic.loading.button:not(.inverted):after {
  border-top-color: #767676;
}
