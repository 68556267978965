
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes zoomFadeIn {
	from {
		transform: scale(0.95);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}
