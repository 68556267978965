.lang-picker {
	display: flex;
	align-items: center;
	position: absolute;
	top: .5rem;
	right: .5rem;
	z-index: 3;
	> a {
		color: $grey;
		margin: 0;
		&::before {
			content: '|';
			padding-left: 0.25rem;
		}
		&:first-child::before {
			content: '';
			padding-left: 0;
		}
	}
}