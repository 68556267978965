.load-config-error {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
